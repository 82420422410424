.root {
    display: flex;
    flex-direction: column;
    gap: 1em;
    align-items: center;
}
.root > input{
    font-size: 1.7em;
    width: 10em;
    padding: .2em .7em;
}
.root > button {
    font-size: 1.7em;
    width: 10em;
}


.root > table, .root > table > * > tr > th, .root > table > * > tr > td {
    border: 2px solid black;
    border-collapse: collapse;
}

.root > table {
    margin-top: 2em;
    width: 65vw;
    font-size: 1.3em;
}

.root > table > * > tr > td {
    text-align: center;
}

.root > table > * > tr > td > input {
    width: 90%;
    font-size: 1em;
}

.root > table > tbody {
    border: 2px solid white;
}

.root > table > tbody:before {
    content: '.';
    line-height: 8vh;
    color: white;
    border: 2px solid white;
}

.root > table > tbody > tr:hover {
    background-color: rgb(234, 232, 232);
    cursor: pointer;
}