.root {
    display: flex;
    flex-direction: column;
    gap: 2em;
    align-items: center;
}

.root > input {
    font-size: 1.5em;
    align-self: flex-start;
    margin-left: 16em;
}

.root > button {
    font-size: 1.8em;
    width: 5em;
}
.root > table, .root > table > tr > th, .root > table > tr > td {
    border: 2px solid black;
    border-collapse: collapse;
}

.root > table {
    margin-top: 2em;
    width: 60vw;
    font-size: 1.3em;
}

.root > table > tr > td {
    text-align: center;
}

.root > table > tr > td > input {
    width: 90%;
    font-size: 1em;
}