.root {
    display: flex;
    flex-direction: column;
    gap: 2em;
    width: 100%;

}

.input {
    display: flex;
    flex-direction: column;
    width: 20em;
    padding: .5em;
    gap: .5em;
}

.input > div {
    display: flex;
    flex-direction: row;
    gap: 1em;
    width: 100%;
}

.input > input {
    font-size: 1.7em;
}

.input > div > input {
    width: 90%;
}

.input > div > input , .input > div > span{
    cursor: pointer;
    font-size: 1.7em;
}

.input > div > span:hover {
    color: #8f8f8f;
}

.input > button {
    font-size: 1.5em;
    border-radius: .5em;
    cursor: pointer;
}

.row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 5em;
    margin-left: 1em;
    margin-right: 1em;
}

.cell {
    flex: auto;
    height: 28em;
    width: 20em;
    min-width: 350px;
    min-height: 600px;
}

.column {
    display: flex;
    flex-direction: column;
    margin-left: 1em;
    margin-right: 1em;
}

.column > span {
    font-size: 1.5em;
}



.meals, .meals > tr > td, .meals > tr > th {
    border: 1px solid;
    border-collapse: collapse;
    text-align: center;
}

.meals {
    margin: 2em;
    margin-top: 0em;
}