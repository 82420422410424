.root {
    display: flex;
    flex-direction: column;
    margin-top: -4em;
}

.input {
    display: flex;
    flex-direction: column;
    width: 25em;
    padding: .5em;
    gap: .5em;
}
.input > input {
    font-size: 1.7em;
}

.row {
    flex: 1;
    display: flex;
    flex-direction: row;
    gap: 5em;
    margin-top: 5em;
    flex-wrap: wrap;
}

.column {
    display: flex;
    flex-direction: column;
    margin-left: 1em;
    margin-right: 1em;
}

.column > span {
    font-size: 1.5em;
}

.cell {
    flex: auto;
    display: flex;
    flex-direction: column;
    gap: .3em;
    width: 47%;
    min-width: 350px;
    padding: 2em;
    box-sizing: border-box;
}

.table {
    border-collapse: collapse;
}

.td {
    padding-bottom: .3em;
    padding-left: .4em;
    padding-right: .4em;
    padding-top: .3em;
    font-size: 1.2em;
    font-weight: bold;
    text-align: center;
    border: 1px solid black;

}