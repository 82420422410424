.root {
    display: flex;
    flex-direction: column;
    align-items: center;
}


.info {
    display: flex;
    flex-direction: row;
    gap:  2em;
    font-size: 1.4em;
    padding: 1em;
}

.info > span {
    font-size: 1em;
    padding: .2em .5em;
}
.info > a {
    text-decoration: none;
    font-weight: bold;
    color: #5e0bb5;
}

.active {
    border-bottom: 2px solid #5e0bb5;
    color: brown;
}

.button:active {
    transform: translateY(5px);
}