.root {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: -4em;
}

.input {
    display: flex;
    flex-direction: column;
    width: 25em;
    padding: .5em;
    gap: .5em;
}
.input > input {
    font-size: 1.7em;
}

.row {
    display: flex;
    flex-direction: row;
    gap: 5em;
    margin-left: 1em;
    margin-right: 1em;
    margin-top: 5em;
    flex-wrap: wrap;
    box-sizing: border-box;
}

.column {
    display: flex;
    flex-direction: column;
    margin-left: 1em;
    margin-right: 1em;
}

.column > span {
    font-size: 1.5em;
}
.cell {
    flex: 1;
    height: 33em;
    display: flex;
    flex-direction: column;
    gap: .3em;
    min-width: 350px;
}


.meals, .meals > tr > td, .meals > tr > th {
    border: 1px solid;
    border-collapse: collapse;
    text-align: center;
}

.meals {
    margin: 2em;
    margin-top: 0em;
}