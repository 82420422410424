.root {
    display: flex;
    flex-direction: column;
    gap: 2em;
    align-items: flex-start;
    justify-items: center;
    margin-top: 5em;
    margin-bottom: 2em;

}

.root > div {
    font-size: 1.5em;
}

.root > div > input , .root > div > select {
    font-size: .8em;
}

.root > div > button {
    font-size: 1em;
}

textarea {
    font-size: 1em;
}
select {
    font-size: 1em;
}